// extracted by mini-css-extract-plugin
export var caseContactFormSection = "bT_kw";
export var caseStudyBackground__lineColor = "bT_kt";
export var caseStudyHead__imageWrapper = "bT_kp";
export var caseStudyProjectsSection = "bT_kx";
export var caseStudyQuote__bgLight = "bT_kD";
export var caseStudyQuote__bgRing = "bT_kr";
export var caseStudyQuote__bottomVideo = "bT_kR";
export var caseStudyVideo = "bT_kS";
export var caseStudyVideo__ring = "bT_kG";
export var caseStudy__bgDark = "bT_km";
export var caseStudy__bgLight = "bT_kl";